.get-an-instant-code-fba-modal {
  width: 1390px !important;

  .ant-modal-content {
    width: 100%;
    padding: 80px 30px;
    background: #f5f5f7;
    .ant-modal-body {
      width: 100%;
    }
  }

  .get-an-instant-code-fba-container {
    width: 100%;
    background: #f5f5f7;
    display: flex;
    justify-content: space-between;

    .get-an-instant-code-fba-link {
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: 0;
      text-align: left;
      color: #3B87B2;
      text-transform: uppercase;
      padding: 0 4px;
    }

    .get-an-instant-code-fba-left-container {
      width: 700px;
      display: flex;
      align-items: center;
      .get-an-instant-code-fba-left-title {
        font-weight: 700;
        font-size: 32px;
        line-height: 41px;
        color: #3B87B2;
        text-transform: uppercase;
        margin-bottom: 50px;
      }

      .get-an-instant-code-fba-left-des {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #000000;
        margin-bottom: 30px;
      }

      .get-an-instant-code-fba-left-tip {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #000000;
        margin-bottom: 50px;
      }

      .get-an-instant-code-fba-left-img-container {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .get-an-instant-code-fba-right-container {
      width: 570px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: #fff;
      border-radius: 20px;
      padding: 50px;
      
      .get-an-instant-code-fba-right-title {
        color: #042459;
        font-size: 32px;
        font-weight: 600;
        line-height: 44.8px;
        letter-spacing: 0;
        // text-align: center;
        // margin-bottom: 49px;
      }
      .get-an-instant-code-fba-right-title-special {
        margin-bottom: 20px;
      }

      .get-an-instant-code-fba-right-btn-1 {
        padding: 0 20px;
        width: 289px;
        height: 40px;
        border-radius: 20px;
        font-weight: 400;
        font-size: 16px;
        color: #FFFFFF;
        margin-bottom: 20px;
        color: #000;
        background: #fff;
        border: 1px solid #000;
      }
      .get-an-instant-code-fba-right-btn-1-active {
        color: #FFFFFF;
        background: #222857;
        border: 1px solid #007EF8;
      }

      .get-an-instant-code-fba-right-btn-2 {
        width: 100%;
        height: 55px;
        // border: 1px solid #111111;
        border-radius: 16px;
        font-weight: 600;
        font-size: 18px;
        line-height: 25.2px;
        color: #fff;
        background: #C6CDD5;
      }
    }
  }
}
