.get-quotes-success-mobile-modal {
  width: 100% !important;

  .ant-modal-content {
    width: 100%;
    padding: 0;

    .ant-modal-body {
      width: 100%;
    }
  }

  .get-quotes-success-1-container {
    width: 100%;
    background: rgba(255, 255, 255, 0.92);
    padding: 30px 15px;

    .get-quotes-success-1-left-container {
      width: 100%;
      margin-bottom: 15px;

      .get-quotes-success-1-left-title {
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        text-transform: uppercase;
        color: #3B87B2;
        margin-bottom: 14px;
      }

      .get-quotes-success-1-left-time-line-container {
        padding: 10px 12px;
        gap: 6px;
        width: 100%;
        height: 100px;
        background: #F2F2F2;
        border-radius: 8px;
        margin-bottom: 14px;
        display: flex;

        .get-quotes-success-1-left-time-line-left-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          width: 9px;

          .get-quotes-success-1-left-time-line-start-icon {
            width: 9px;
            height: 9px;
            border-radius: 50%;
            background-color: #743D73;
          }

          .get-quotes-success-1-left-time-line-left-line {
            flex: 1;
            border-right: 1px dashed #000000;
          }

          .get-quotes-success-1-left-time-line-stop-icon {
            width: 9px;
            height: 9px;
            border-radius: 50%;
            background-color: #FE6F23;
          }
        }

        .get-quotes-success-1-left-time-line-right-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex: 1;

          .get-quotes-success-1-left-time-line-start-content {
            font-size: 9px;
            line-height: 12px;
            color: #000000;

            .get-quotes-success-1-left-time-line-start-top-container {
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-weight: 300;
              margin-bottom: 8px;

              img {
                width: 3px;
              }
            }

            .get-quotes-success-1-left-time-line-start-text {
              font-weight: 500;
            }
          }

          .get-quotes-success-1-left-time-line-stop-content {
            font-size: 9px;
            line-height: 12px;
            color: #000000;

            .get-quotes-success-1-left-time-line-stop-top-container {
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-weight: 300;
              margin-bottom: 4px;

              img {
                width: 3px;
              }
            }

            .get-quotes-success-1-left-time-line-stop-text {
              font-weight: 500;
            }
          }
        }
      }

      .get-quotes-success-1-left-info-container {
        padding: 10px 12px;
        width: 100%;
        background: #F2F2F2;
        border-radius: 8px;
        margin-bottom: 14px;
        display: flex;
        justify-content: space-between;

        .get-quotes-success-1-left-info-title {
          width: 20px;
          border-right: 1px dashed #000000;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-right: 6px;
          margin-right: 10px;
          > div.vertical {
            font-weight: 700;
            font-size: 10px;
            line-height: 13px;
            text-transform: uppercase;
            color: #000000;
            transform: unset;
          }
          > div {
            font-weight: 700;
            font-size: 10px;
            line-height: 13px;
            text-transform: uppercase;
            color: #000000;
            transform: rotate(-90deg);
          }
        }

        .get-quotes-success-1-left-info-right-container {
          display: flex;
          flex-direction: column;
          flex: 1;

          .get-quotes-success-1-left-info-right-top-container {
            border-bottom: 1px solid rgba(0, 0, 0, 0.3);
            padding-bottom: 5px;
            margin-bottom: 15px;

            .get-quotes-success-1-left-info-money {
              font-weight: bold;
              font-size: 15px;
              line-height: 18px;
              color: #000000;
              display: flex;
              justify-content: space-between;
              gap: 20px;
              .special-rates {
                font-size: 9px;
                line-height: 10px;
                font-weight: 400
              }
            }

            .get-quotes-success-1-left-info-tip {
              font-weight: 400;
              font-size: 9px;
              line-height: 12px;
            }

            .get-quotes-success-1-left-info-valid {
              font-weight: 400;
              font-size: 9px;
              line-height: 12px;
              color: #EE1D52;
            }
          }

          .get-quotes-success-1-left-info-content-container {
            display: flex;
            flex-direction: column;
            gap: 3px;
            margin-bottom: 15px;

            .get-quotes-success-1-left-info-content-item-container {
              min-height: 14px;
              padding: 3px 0;
              font-style: normal;
              font-weight: 500;
              font-size: 9px;
              line-height: 11px;
              color: #000000;
              border-bottom: 1px dashed rgba(0, 0, 0, 0.3);
              display: flex;
              align-items: center;

              &:last-child {
                border-bottom: none;
              }

              .get-quotes-success-1-left-info-content-item-key {
                margin-right: 4px;
              }
              .special-instruction-text-wrap{
                text-wrap: wrap;
              }
            }
          }

          .get-quotes-success-1-left-info-bottom-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-top: 1px solid rgba(0, 0, 0, 0.3);
            padding-top: 3px;

            .get-quotes-success-1-left-info-bottom-text {
              font-weight: 500;
              font-size: 9px;
              line-height: 11px;
              color: #000000;
            }

            .get-quotes-success-1-left-info-bottom-miles {
              font-weight: 400;
              font-size: 9px;
              line-height: 11px;
              color: #EE1D52;
            }
          }
        }
      }

      .get-quotes-success-1-left-map-container {
        width: 100%;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }

    .get-quotes-success-1-right-container {
      width: 100%;

      .get-quotes-success-1-right-collapse {
        margin-bottom: 35px;

        .ant-collapse-item {
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }

          .ant-collapse-header {
            padding: 0;
            margin-bottom: 10px;
            position: relative;

            .ant-collapse-expand-icon {
              position: absolute;
              right: 0;
              height: 11px;

              .get-quotes-success-1-right-expand-icon-container {
                width: 12px;
                height: 12px;
                padding: 3px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.3s;

                img {
                  max-width: 100%;
                  max-height: 100%;
                }
              }
            }

            .get-quotes-success-1-right-header-container {
              .get-quotes-success-1-right-header-title {
                font-weight: 700;
                font-size: 10px;
                line-height: 12px;
                text-transform: uppercase;
                color: #061C3D;
              }
              .get-quotes-success-1-right-header-des {
                padding-top: 3px;
                font-weight: 400;
                font-size: 9px;
                line-height: 11px;
                color: #EE1D52;
              }
            }
          }

          .ant-collapse-content {
            padding: 13px 12px;
            gap: 6px;
            background: #F2F5FA;
            border-radius: 8px;

            .ant-collapse-content-box {
              padding: 0;

              .get-quotes-success-1-right-list-item-container {
                display: flex;
                justify-content: space-between;
                padding: 3px 0;
                gap: 3px;
                border-bottom: 1px dashed rgba(0, 0, 0, 0.3);
                margin-bottom: 6px;

                &:last-child {
                  border-bottom: none;
                  margin-bottom: 0;
                }

                .get-quotes-success-1-right-list-item-left-container {
                  flex: 1;

                  .get-quotes-success-1-right-list-item-title {
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 12px;
                    color: #000000;
                  }
                  .get-quotes-success-1-right-list-item-des {
                    font-weight: 400;
                    font-size: 9px;
                    line-height: 11px;
                    color: #000000;
                  }
                }

                .get-quotes-success-1-right-list-item-sub-title {
                  width: 100px;
                  font-weight: 400;
                  font-size: 9px;
                  line-height: 11px;
                  color: #000000;
                  text-align: right;
                }
              }
            }
          }
        }

        .ant-collapse-item-active {
          .ant-collapse-header {
            .ant-collapse-expand-icon {
              .get-quotes-success-1-right-expand-icon-container {
                transform: rotate(90deg);
              }
            }
          }
        }
      }
    }
  }

  .get-quotes-success-2-container {
    width: 100%;
    background: rgba(255, 255, 255, 0.92);
    padding: 50px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .get-quotes-success-2-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      text-transform: uppercase;
      color: #3B87B2;
      margin-bottom: 25px;
    }

    .get-quotes-success-2-des {
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      text-align: center;
      color: #000000;
      margin-bottom: 25px;
    }

    .get-quotes-success-2-tip {
      width: 100%;
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      text-align: center;
      color: #EE1D52;
      margin-bottom: 25px;
    }
  }

  .get-quotes-success-btn {
    width: 100%;
    height: 32px;
    border: 1px solid #111111;
    border-radius: 20px;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    color: #111111;
  }
}
