.w-google-maps-select-mobile-container {
    width: 100%;
    height: 25px;
    
    .w-google-maps-select-select {
      width: 100%;
      height: 100%;
      
      .ant-select-selector {
        height: 100%;
        border: 1px solid #000 !important;
        border-radius: 4px !important;
        font-weight: 400;
        font-size: 9px !important;
  
        .ant-select-selection-search {
          height: 100%;
          input {
            height: 100%;
            font-weight: 400;
            font-size: 16px;
          }
        }
  
        .ant-select-selection-item,
        .ant-select-selection-placeholder {
          display: flex;
          align-items: center;
        }
      }
    }
  }
  