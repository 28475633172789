.feedback-mobile-modal {
  width: 100% !important;

  .ant-modal-content {
    width: 100%;
    padding: 50px 15px;

    .ant-modal-body {
      width: 100%;
    }
  }

  .feedback-container {
    width: 100%;
    background: rgba(255, 255, 255, 0.92);
    display: flex;
    flex-direction: column;
    align-items: center;

    .feedback-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      text-transform: uppercase;
      color: #042459;
      margin-bottom: 25px;
    }

    .feedback-des {
      font-weight: 700;
      font-size: 10px;
      line-height: 15px;
      text-align: center;
      color: #042459;
      margin-bottom: 25px;
    }

    .feedback-form-container {
      width: 100%;

      .feedback-form-row {
        display: flex;
        gap: 0 20px;

        .ant-form-item {
          flex: 1;
        }
      }
      .formLabel {
        color: #042459;
        font-weight: 700;
        font-size: 9px;
      }

      .ant-form-item {
        margin-bottom: 20px;

        .ant-form-item-label {
          font-weight: 400;
          font-size: 9px;
          line-height: 11px;
          color: #666666;
          padding-bottom: 6px;

          .ant-form-item-required {
            &:after {
              content: '*';
              display: block;
              color: #EE1D52;
            }
          }
        }

        .ant-input {
          height: 28px;
          border: 1px solid #000;
          border-radius: 6px;
          font-size: 9px;
        }
      }
    }

    .feedback-btn-1 {
      width: 100%;
      height: 32px;
      // border: 1px solid #111111;
      border-radius: 20px;
      font-weight: 400;
      font-size: 10px;
      line-height: 13px;
      color: #fff;
      background: #007EF8;
      margin-bottom: 10px;
    }

    .feedback-btn-2 {
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      width: 100%;
      height: 32px;
      color: #fff;
      background: #C6CDD5;
      border-radius: 20px;
    }
  }

  .feedback-success-container {
    width: 100%;
    background: rgba(255, 255, 255, 0.92);
    display: flex;
    flex-direction: column;
    align-items: center;

    .feedback-success-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      text-transform: uppercase;
      color: #3B87B2;
      margin-bottom: 25px;
    }

    .feedback-success-des {
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      text-align: center;
      color: #000000;
      margin-bottom: 25px;
    }

    .feedback-success-btn {
      width: 100%;
      height: 32px;
      border: 1px solid #111111;
      border-radius: 20px;
      font-weight: 400;
      font-size: 10px;
      line-height: 13px;
      color: #111111;
    }
  }
}
