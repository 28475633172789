.getAnInstantCodeOTR-mobile {
  width: 100% !important;
  min-width: 265px;
  .get-an-instant-code-fba-container{
    // background: #f5f5f7;
    // display: flex;
    // justify-content: space-between;
    .get-an-instant-code-fba-left-container {
      width: 100%;
      // margin-bottom: 15px;
      .get-an-instant-code-fba-left-img-container {
        width: 100%;
        // margin-bottom: 15px;
        // height: 700px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .get-an-instant-code-fba-right-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: #fff;
      border-radius: 20px;
      // padding: 50px;
    }
  }
  .ant-modal-content {
    padding: 20px 45px;
    .ant-modal-body {
      text-align: center;
      margin: 20px 0;
      .getAnInstantCodeOTR-title {
        font-weight: 700;
        font-size: 12px;
        line-height: 21px;
        color: #042459;
      }
      .getAnInstantCodeOTR-text {
        font-weight: 400;
        font-size: 10px;
        line-height: 20px;
        color: #ee1d52;
        margin: 20px 0;
      }
      .getAnInstantCodeOTR-refresh {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #3b87b2;
        margin: 20px 0;
      }
      .getAnInstantCode-vehicleType {
        width: 100%;
        .getAnInstantCode-vehicleType-title {
          text-align: left;
          > h1 {
            font-weight: 700;
            font-size: 24px;
            line-height: 28px;
            color: #042459;
          }
        }
        .getAnInstantCode-vehicleType-btns {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .getAnInstantCode-vehicleType-btn-1 {
            padding: 0 20px;
            //width: 25%;
            height: 40px;
            border-radius: 4px;
            font-weight: 400;
            font-size: 16px;
            background: #FFFFFF;
            color: #000;
            margin-bottom: 15px;
            line-height: 11px;
            border: 1px solid #000;
          }
          .getAnInstantCode-vehicleType-btn-2 {
            padding: 0 20px;
            //width: 25%;
            height: 40px;
            border-radius: 4px;
            font-weight: 400;
            font-size: 16px;
            background: #222857;
            color: #FFFFFF;
            margin-bottom: 15px;
            line-height: 11px;
            border: 1px solid #007EF8;
          }
        }
      }
      .getAnInstantCodeOTR-from {
        .getAnInstantCodeOTR-from-left {
          width: 100%;
          text-align: left;
          padding-bottom: 22px;
          .getAnInstantCodeOTR-from-left-div {
            // height: 100px;
            > h1 {
              font-weight: 700;
              font-size: 24px;
              line-height: 28px;
              color: #042459;
            }
            .ant-input {
              font-size: 16px;
            }
            .ant-select {
              width: 100%;
              .ant-select-selector {
                height: 45px;
                border: 1px solid #000;
                border-radius: 4px !important;
                font-size: 16px !important;
              }
            }
          }
        }
        .getAnInstantCodeOTR-from-right {
          width: 100%;
          text-align: left;
          .getAnInstantCodeOTR-from-right-div {
            > h1 {
              font-weight: 700;
              font-size: 24px;
              line-height: 28px;
              color: #042459;

            }
            .ant-select {
              height: 50px;
              width: 100%;
              .ant-select-selector {
                height: 100%;
                border: 1px solid #000;
                border-radius: 4px !important;
                font-size: 16px !important;
                .ant-select-selection-search {
                  height: 100%;
                  input {   
                    height: 25px !important;   
                    line-height: 11px !important;
                    font-weight: 400;
                    font-size: 16px;
                  }
                }
                .ant-select-selection-search {
                  .ant-select-selection-search-input {
                    font-size: 16px;
                  }
                }
                .ant-select-selection-item,
                  .ant-select-selection-placeholder {
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                  }
              }
            }
          }
        }
      }
      .getAnInstantCodeOTR-mar {
        margin: 30px 0;
        .getAnInstantCodeOTR-mar-ul {
          margin: 0;
          padding: 0;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .getAnInstantCodeOTR-mar-ul-li {
            width: 100px;
            height: 70px;
            border: 1px solid #0dc9dd;
            border-radius: 8px;
            margin: 30px 10px 0;
            padding: 10px;
            > h1 {
              font-weight: 400;
              font-size: 14px;
              line-height: 30px;
              color: #061c3d;
              margin: 0;
            }
            > p {
              font-weight: 700;
              font-size: 12px;
              line-height: 30px;
              color: #3b87b2;
              margin: 0;
            }
          }
        }
      }
      .getAnInstantCodeOTR-leftText {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #3b87b2;
        text-align: left;
        margin: 20px 0;
      }
      .getAnInstantCodeOTR-btn {
        width: 100%;
        height: 50px;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        border-radius: 8px;
        color: #fff;
        background: #007EF8;
        margin-bottom: 10px;
        &:last-of-type {
          background: #C6CDD5;
        }
      }
    }
  }
}
