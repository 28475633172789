.blog-mobile-container {
  width: 100vw;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .blog-top-container {
    .blog-banner-container {
      width: 100%;
      margin-bottom: 10px;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .blog-top-left-container {
      width: 100%;
      padding: 5px;
      margin-right: 15px;
      margin-bottom: 50px;

      .blog-top-left-des {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #393939;
      }
    }

    .blog-top-view-container {
      width: 100%;
      margin-bottom: 50px;

      .blog-top-view-title {
        padding: 5px 0;
        background-color: #FBFBFB;
        text-align: center;
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
        color: #3B87B2;
        margin-bottom: 25px;
      }

      .blog-top-view-item-container {
        padding: 20px 10px 20px 5px;
        background: #FBFBFB;
        border-radius: 8px;
        display: flex;
        align-items: center;
        overflow: hidden;
        margin-bottom: 20px;

        .blog-top-view-item-img-container {
          margin-right: 20px;
          width: 75px;
          height: 75px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }

        .blog-top-view-item-des {
          flex: 1;
          font-weight: 400;
          font-size: 16px;
          line-height: 26px;
          display: flex;
          align-items: center;
          color: #4F4F4F;
        }
      }
    }
  }

  .blog-more-articles-container {
    .blog-more-articles-title {
      font-weight: 700;
      font-size: 36px;
      line-height: 46px;
      text-align: center;
      text-transform: uppercase;
      color: #0DC9DD;
      margin-bottom: 100px;
    }

    .blog-more-articles-list-container {
      display: grid;
      gap: 25px 50px;
      grid-template: repeat(3, 1fr) / repeat(3, 1fr);
      margin-bottom: 50px;

      .blog-more-articles-list-item-container {
        width: 330px;
        height: 358px;
        cursor: pointer;

        .blog-more-articles-list-item-img-container {
          width: 330px;
          height: 250px;
          border-radius: 8px;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 10px;

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }

        .blog-more-articles-list-item-info-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;

          .blog-more-articles-list-item-info-left-container {
            display: flex;
            align-items: center;

            .blog-more-articles-list-item-info-left-icon-container {
              width: 15px;
              height: 15px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 5px;

              img {
                max-width: 100%;
                max-height: 100%;
              }
            }

            .blog-more-articles-list-item-info-left-text {
              font-weight: 400;
              font-size: 12px;
              line-height: 26px;
              color: #4F4F4F;
            }
          }

          .blog-more-articles-list-item-info-date {
            font-weight: 400;
            font-size: 12px;
            line-height: 26px;
            color: #4F4F4F;
          }
        }

        .blog-more-articles-list-item-des {
          font-weight: 700;
          font-size: 20px;
          line-height: 26px;
          color: #0DC9DD;
        }
      }
    }

    .blog-more-articles-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px 20px;
      gap: 10px;
      background: #3B87B2;
      border-radius: 8px;
      width: max-content;
      margin: 0 auto 100px;

      font-weight: 400;
      font-size: 18px;
      line-height: 23px;
      color: #FFFFFF;
      cursor: pointer;
      user-select: none;
    }
  }
}
