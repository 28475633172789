.getAnInstantCode-mobile-main {
  width: 100%;
  padding: 15px 15px 0;
  background-color: #F5F5F7;

  .getAnInstantCode-main-title {
    color: #000;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 30px;
  }

  .getAnInstantCode-main-content {
    .getAnInstantCode-main-content-ul {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      padding: 0;
      //3/2
      .getAnInstantCode-main-content-ul-li {
        width: 280px;
        height: 187px;
        position: relative;
        cursor: pointer;
        margin-bottom: 36px;
        background-color: #fff;
        display: flex;
        justify-content: center;

        > img {
          width: 197px;
          height: 197px;
        }

        > div {
          width: 100%;
          // height: 33px;
          // padding: 0 10px;
          // display: flex;
          // justify-content: center;
          // align-items: center;
          gap: 5px;
          flex-shrink: 0;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 12px;
          text-transform: uppercase;
          color: #042459;

          position: absolute;
          left: 30px;
          top: 20px;
          // transform: translate(-50%, 0);

          > span {
            font-weight: 400;
          }
        }
      }
    }
  }
}
