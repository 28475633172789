.header-main {
  width: 100%;
  .header-main-header-container {
    width: 100%;
    height: 96px;
    background: #042459;
    position: relative;
    display: flex;
    align-items: center;
    img{
      position: absolute;
      left: 40px;
      width: 214px;
      height: 66px
    }

    .header-main-header-main {
      width: 1020px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 0 auto;

      .header-main-header-main-left {
        height: 100%;

        .header-main-header-main-left-ul {
          height: 100%;
          list-style: none;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0;
          padding: 0;

          .header-main-header-main-left-ul-li {
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;

            img {
              width: 31px;
              cursor: pointer;
            }
          }
        }
      }

      .header-main-header-main-right {
        height: 100%;

        .header-main-header-main-right-ul {
          height: 100%;
          list-style: none;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0;
          padding: 0;

          .header-main-header-main-right-ul-li {
            margin-right: 20px;
            font-size: 15px;
            line-height: 21px;
            text-align: center;
            color: #ffffff;
            cursor: pointer;
            font-weight: 300;
          }
        }
      }
    }
  }
}

.header-get-an-instant-code-modal {
  width: 1475px !important;

  .ant-modal-content {
    width: 100%;
    padding: 0;

    .ant-modal-body {
      width: 100%;
    }
  }
}
