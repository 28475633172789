.get-an-instant-code-port-rail-drayage-mobile-modal {
  min-width: 270px;
  .ant-modal-content {
    width: 100%;
    padding: 30px 15px;
    background: #f5f5f7;

    .ant-modal-body {
      width: 100%;
    }
  }

  .get-an-instant-code-port-rail-drayage-container {
    width: 100%;
    background: #f5f5f7;

    .get-an-instant-code-port-rail-drayage-left-container {
      width: 100%;
      margin-bottom: 30px;

      .get-an-instant-code-port-rail-drayage-left-title {
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        color: #3B87B2;
        text-transform: uppercase;
        margin-bottom: 25px;
      }

      .get-an-instant-code-port-rail-drayage-left-des {
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        color: #000000;
        margin-bottom: 15px;
      }

      .get-an-instant-code-port-rail-drayage-left-tip {
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        color: #000000;
        margin-bottom: 25px;
      }

      .get-an-instant-code-port-rail-drayage-left-img-container {
        width: 100%;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }

    .get-an-instant-code-port-rail-drayage-right-container {
      width: 100%;
      // background-color: #FFFFFF;
      // padding: 50px;
      // border-radius: 20px;

      .get-an-instant-code-port-rail-drayage-right-title {
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
        // text-transform: uppercase;
        color: #042459;
        margin-bottom: 10px;
      }

      .get-an-instant-code-port-rail-drayage-transport-type-list-container {
        display: flex;
        flex-wrap: wrap;
        gap: 0 5px;
        margin-bottom: 20px;

        .get-an-instant-code-port-rail-drayage-transport-type-item-container {
          width: 96px;
          padding: 0 19px;
          border: 1px solid #000000;
          border-radius: 4px;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #000000;
          height: 40px;
        }

        .get-an-instant-code-port-rail-drayage-transport-type-active-item-container {
          border-color: #007EF8;
          color: #FBFBFB;
          background-color: #222857;
        }
      }

      .get-an-instant-code-port-rail-drayage-pick-up-location-list-container {
        display: flex;
        flex-wrap: wrap;
        gap: 15px 5px;
        margin-bottom: 20px;

        .get-an-instant-code-port-rail-drayage-pick-up-location-item-container {
          padding: 0 19px;
          border: 1px solid #000000;
          border-radius: 4px;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #000000;
          height: 40px;
        }

        .get-an-instant-code-port-rail-drayage-pick-up-location-active-item-container {
          border-color: #007EF8;
          color: #FBFBFB;
          background-color: #222857;
        }

        .get-an-instant-code-port-rail-drayage-pick-up-location-item-box-shadow {
          // box-shadow: 0 2px 2px 0 #0A66C2;
        }
      }

      .get-an-instant-code-port-rail-drayage-right-search-container {
        position: relative;
        height: 50px;
        margin-bottom: 25px;

        .get-an-instant-code-port-rail-drayage-right-input-icon {
          width: 24px;
          height: 24px;
          position: absolute;
          left: 19px;
          top: 50%;
          transform: translateY(-50%);
          z-index: 9;  
        }

        .get-an-instant-code-port-rail-drayage-right-input-container {
          width: 100%;
          height: 100%;

          .ant-select-selector {
            height: 100%;
            width: 100%;
            padding: 0 19px 0 53px;
            border: 1px solid #000000 !important;
            border-radius: 4px;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;

            .ant-select-selection-search {
              height: 100%;
              inset-inline-start: 47px;
              inset-inline-end: 10px;

              input {
                height: 100%;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
              }
            }

            .ant-select-selection-item,
            .ant-select-selection-placeholder {
              display: flex;
              align-items: center;
            }
          }
        }
      }

      .get-an-instant-code-port-rail-drayage-right-btn {
        width: 100%;
        height: 50px;
        line-height: 25px;
        background: #007EF8;
        border-radius: 8px;
        font-weight: 700;
        font-size: 18px;
        color: #FFFFFF;
        font-family: PingFang TC;
      }
    }
  }
}
