.blog-mobile-detail-container {
  width: 100%;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .blog-detail-top-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .blog-detail-top-img-container {
      width: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .blog-detail-top-info-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;

      .blog-detail-top-info-left-container {
        display: flex;
        align-items: center;

        .blog-detail-top-info-left-icon-container {
          width: 8px;
          height: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 3px;

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }

        .blog-detail-top-info-left-text {
          font-weight: 400;
          font-size: 9px;
          line-height: 12px;
          color: #4F4F4F;
        }
      }

      .blog-detail-top-info-date {
        font-weight: 400;
        font-size: 9px;
        line-height: 12px;
        color: #4F4F4F;
      }
    }

    .blog-detail-top-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      color: #393939;
      margin-bottom: 15px;
    }

    .blog-detail-top-des-container {
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      color: #686A6F;
      margin-bottom: 55px;

      .blog-detail-top-des-bold {
        font-weight: 700;
      }

      .blog-detail-top-des-margin-bottom-1 {
        margin-bottom: 15px;
      }

      a {
        color: #686A6F !important;
      }
    }
  }
}
