.blog-articles-item-container {
  width: 330px;
  height: 358px;
  cursor: pointer;

  .blog-articles-item-img-container {
    width: 330px;
    height: 250px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .blog-articles-item-info-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    .blog-articles-item-info-left-container {
      display: flex;
      align-items: center;

      .blog-articles-item-info-left-icon-container {
        width: 15px;
        height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5px;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      .blog-articles-item-info-left-text {
        font-weight: 400;
        font-size: 12px;
        line-height: 26px;
        color: #4F4F4F;
      }
    }

    .blog-articles-item-info-date {
      font-weight: 400;
      font-size: 12px;
      line-height: 26px;
      color: #4F4F4F;
    }
  }

  .blog-articles-item-des {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: #0DC9DD;
  }
}
