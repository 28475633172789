.getAnInstantCode-main {
  width: 100%;
  padding: 50px;
  background-color: #F5F5F7;

  .getAnInstantCode-main-title {
    color: #000;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 70px;
  }

  .getAnInstantCode-main-content {
    .getAnInstantCode-main-content-ul {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      padding: 0;

      .getAnInstantCode-main-content-ul-li {
        width: 570px;
        height: 497px;
        background-color: #fff;
        padding: 50px;
        position: relative;
        cursor: pointer;
        margin-bottom: 150px;
        display: flex;
        justify-content: center;
        margin-bottom: 60px;

        > img {
          width: 497px;
          height: 497px;
        }

        > div {
          width: 100%;
          // height: 66px;
          // padding: 0 20px;
          // display: flex;
          // justify-content: center;
          // align-items: center;
          gap: 10px;
          flex-shrink: 0;
          font-size: 28px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          text-transform: uppercase;
          color: #042459;

          position: absolute;
          left: 50px;
          top: 80px;
          // transform: translate(-50%, 0);

          > span {
            font-weight: 400;
          }
        }
      }
    }
  }
}
