.contactToBook-mobile {
  width: 100% !important;
  .ant-modal-content {
    padding: 20px 45px;
    .ant-modal-body {
      text-align: center;
      margin: 20px 0;
      > h1 {
        width: 100%;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #3b87b2;
      }
      > p {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
        text-align: center;
        margin: 20px 0;
      }
      .contactToBook-form-main {
        .ant-form {
          margin: 0;
        }
        .ant-form-item {
          .ant-input {
            height: 36px;
            font-size: 14px;
          }
          .ant-form-item-label {
            > label {
              font-size: 14px;
            }
            .ant-form-item-required {
              &:after {
                content: '*';
                display: block;
                color: #ee1d52;
              }
            }
          }
          .ant-select {
            .ant-select-selector {
              height: 36px;
              border: 1px solid rgba(102, 102, 102, 0.35);
              border-radius: 12px;
              font-size: 14px;

              .ant-select-selection-search-input {
                height: 100%;
              }

              .ant-select-selection-placeholder {
                height: 100%;
                line-height: 30px;
              }

              .ant-select-selection-item {
                height: 100%;
                line-height: 30px;
              }
            }
          }

          .ant-select-dropdown {
            .ant-select-item-option,
            .ant-select-item {
              height: 100%;
              line-height: 34px;
              font-size: 14px;
            }
          }
        }
        .display-center {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .ant-form-item {
            width: 46%;
          }
        }
        .ant-form-item-control-input-content {
          > p {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #000000;
            > a {
              margin: 0 10px;
              text-decoration: underline;
              color: #000000;
            }
          }
          .ant-btn:first-of-type {
            width: 100%;
            height: 34px;
            margin: 10px 0;
            font-size: 14px;
            color: #111111;
            border: 1px solid #111111;
            border-radius: 40px;
          }
          .ant-btn:last-of-type {
            width: 100%;
            height: 34px;
            margin: 10px 0;
            font-size: 14px;
            color: #3b87b2;
            border-radius: 40px;
          }
        }
      }
    }
  }
}
