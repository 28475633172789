.blog-mobile-articles-container {
  padding-bottom: 50px;
  width: 100%;

  .blog-articles-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    text-transform: uppercase;
    color: #0DC9DD;
    margin-bottom: 50px;
  }

  .blog-articles-list-container {
    width: 100%;

    .blog-articles-list-item-container {
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .blog-articles-btn-container {
    padding-top: 25px;

    .blog-articles-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 10px;
      gap: 5px;
      background: #3B87B2;
      border-radius: 4px;
      width: max-content;
      margin: 0 auto;

      font-weight: 400;
      font-size: 9px;
      line-height: 12px;
      color: #FFFFFF;
      cursor: pointer;
      user-select: none;
    }
  }
}
