.get-quotes-mobile-modal {
  .ant-modal-content {
    width: 100%;
    padding: 50px 15px;

    .ant-modal-body {
      width: 100%;
    }
  }

  .get-quotes-container {
    width: 100%;
    background: rgba(255, 255, 255, 0.92);
    display: flex;
    flex-direction: column;
    align-items: center;

    .get-quotes-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      text-transform: uppercase;
      color: #3B87B2;
      margin-bottom: 25px;
    }

    .get-quotes-des {
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      text-align: center;
      color: #000000;
      margin-bottom: 25px;
    }

    .get-quotes-form-container {
      margin-bottom: 20px;
      width: 100%;

      .get-quotes-form-row {
        display: flex;
        gap: 0 20px;

        .ant-form-item {
          flex: 1;
        }
      }

      .ant-form-item {
        margin-bottom: 20px;

        .ant-form-item-label {
          font-weight: 400;
          font-size: 9px;
          line-height: 11px;
          color: #666666;
          padding-bottom: 6px;

          .ant-form-item-required {
            &:after {
              content: '*';
              display: block;
              color: #EE1D52;
            }
          }
        }

        .ant-input {
          height: 28px;
          border: 1px solid rgba(102, 102, 102, 0.35);
          border-radius: 6px;
          font-size: 9px;
        }

        .ant-select {
          .ant-select-selector {
            height: 28px;
            border: 1px solid rgba(102, 102, 102, 0.35);
            border-radius: 6px;
            font-size: 9px;

            .ant-select-selection-search-input {
              height: 100%;
            }

            .ant-select-selection-placeholder {
              height: 100%;
              line-height: 26px;
            }

            .ant-select-selection-item {
              height: 100%;
              line-height: 26px;
            }
          }
        }

        .ant-select-dropdown {
          .ant-select-item-option,
          .ant-select-item  {
            height: 100%;
            line-height: 26px;
            font-size: 9px;
          }
        }
      }
    }

    .get-quotes-tip {
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      color: #000000;
      margin-bottom: 20px;
    }

    .get-quotes-link {
      color: #000000;
      text-decoration: underline;
    }

    .get-quotes-btn-1 {
      width: 100%;
      height: 32px;
      border: 1px solid #111111;
      border-radius: 20px;
      font-weight: 400;
      font-size: 10px;
      line-height: 13px;
      color: #111111;
      margin-bottom: 20px;
    }

    .get-quotes-btn-2 {
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      color: #3B87B2;
    }
  }
}
