.getAnInstantCodeComingSoon-mobile {
  width: 100% !important;
  .ant-modal-content {
    min-width: 200px;
    padding: 10px 25px;
    .ant-modal-body {
      text-align: center;
      margin: 50px 0;
      > h1 {
        font-weight: 700;
        font-size: 32px;
        line-height: 41px;
        color: #042459;
      }
      > p {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #042459;
        margin: 50px 0;
      }
      > img {
        width: 100%;
        margin-bottom: 50px;
      }
      .ant-btn {
        width: 100%;
        height: 50px;
        border-radius: 8px;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        color: #fff;
        background: #C6CDD5;
      }
    }
  }
}
