body {
  margin: 0;
}

#root {
  height: 100%;
}
.app {
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: column;
}
ul > li {
  list-style: none;
}

* {
  box-sizing: border-box;
  font-family: 'Google Sans', sans-serif;
}
