.get-an-instant-code-walmart-mobile-modal {
  .ant-modal-content {
    width: 100%;
    min-width: 228px;
    padding: 30px 15px;
    background: #F5F5F7;

    .ant-modal-body {
      width: 100%;
    }
  }

  .get-an-instant-code-walmart-container {
    width: 100%;
    background: #F5F5F7;
    padding: 30px 15px;

    .get-an-instant-code-walmart-link {
      font-size: 10px;
      font-weight: 700;
      line-height: 15px;
      letter-spacing: 0;
      text-align: left;
      color: #3B87B2;
      text-transform: uppercase;
      padding: 0 4px;
    }

    .get-an-instant-code-walmart-left-container {
      width: 100%;
      margin-bottom: 15px;

      .get-an-instant-code-walmart-left-title {
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: #3B87B2;
        text-transform: uppercase;
        margin-bottom: 25px;
      }

      .get-an-instant-code-walmart-left-des {
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        color: #000000;
        margin-bottom: 15px;
      }

      .get-an-instant-code-walmart-left-tip {
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        color: #000000;
        margin-bottom: 25px;
      }

      .get-an-instant-code-walmart-left-img-container {
        width: 100%;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }

    .get-an-instant-code-walmart-right-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .get-an-instant-code-walmart-right-title {
        color: #042459;
        font-size: 32px;
        font-weight: 700;
        line-height: 44.8px;
        letter-spacing: 0;
        text-align: center;
        // margin-bottom: 25px;
      }
      .get-an-instant-code-walmart-right-title-special{
        margin-bottom: 20px;
      }

      .get-an-instant-code-walmart-right-btn-1 {
        padding: 0 20px;
        width: 100%;
        height: 40px;
        border-radius: 8px;
        font-weight: 400;
        font-size: 16px;
        color: #FFFFFF;
        margin-bottom: 20px;
        color: #000;
        background: #fff;
        border: 1px solid #000;
      }
      .get-an-instant-code-walmart-right-btn-1-active {
        color: #FFFFFF;
        background: #222857;
        border: 1px solid #007EF8;
      }

      .get-an-instant-code-walmart-right-btn-2 {
        width: 100%;
        height: 50px;
        // border: 1px solid #111111;
        border-radius: 8px;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        color: #fff;
        background: #C6CDD5;
        // padding: 15px 20px;
      }
    }
  }
}
