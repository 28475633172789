.w-google-maps-select-container {
  width: 100%;
  height: 56px;
  
  .w-google-maps-select-select {
    width: 100%;
    height: 100%;
    
    .ant-select-selector {
      height: 100%;
      border: 1px solid rgba(102, 102, 102, 0.35) !important;
      border-radius: 4px;
      font-weight: 400;
      font-size: 16px;

      .ant-select-selection-search {
        height: 100%;

        input {
          height: 100%;
          font-weight: 400;
          font-size: 16px;
        }
      }

      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        display: flex;
        align-items: center;
      }
    }
  }
}
