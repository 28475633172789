.header-mobile-main {
  width: 100%;

  .header-main-header-container {
    width: 100%;
    height: 25px;
    background: #042459;
    padding: 0 15px; 
    position: relative;
    display: flex;
    align-items: center;
    img{
      position: absolute;
      left: 6px;
      width: 60px;
      height: 18px
    }

    .header-main-header-main {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 0 auto;

      .header-main-header-main-left {
        height: 100%;

        .header-main-header-main-left-ul {
          height: 100%;
          list-style: none;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0;
          padding: 0;

          .header-main-header-main-left-ul-li {
            margin-right: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;

            img {
              width: 15px;
              cursor: pointer;
            }
          }
        }
      }

      .header-main-header-main-right {
        height: 100%;

        .header-main-header-main-right-ul {
          height: 100%;
          list-style: none;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0;
          padding: 0;

          .header-main-header-main-right-ul-li {
            margin-right: 10px;
            font-size: 10px;
            line-height: 13px;
            text-align: center;
            color: #ffffff;
            cursor: pointer;
            font-weight: 300;
            .ant-btn {
              height: 25px !important;
              padding: 2px !important;
              border-radius: 3px;
              font-size: 9px;
            }
          }
        }
      }
    }
  }
}

.header-mobile-get-an-instant-code-modal {
  width: 100% !important;
  
  .ant-modal-content {
    width: 100%;
    padding: 0;
    
    .ant-modal-body {
      width: 100%;
      height: 80%;
    }
  }
}
