.footer-mobile-main {
  width: 100%;
  height: 20px;
  line-height: 20px;
  text-align: center;
  color: #fff;
  font-size: 9px;
  background: #042459;
  > span {
    font-weight: bold;
  }
}
