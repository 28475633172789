.home-mobile-container {
  width: 100%;
  position: relative;
  flex: 1;
  background-image: url("../../assets/images/home/backgroundImg.png");
  background-color: #F5F5F7;
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-position: right center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center bottom;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding: 80px 15px 0;

  .home-top-container {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 50px;

    .home-top-item-container {
      width: 75px;
      height: 75px;
      padding: 3px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #FFF;
      overflow: hidden;

      .home-top-item-title {
        color: #1F3EDE;
        text-align: center;
        font-size: 9px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        letter-spacing: -1px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 95%;
      }

      .home-top-item-img-container {
        width: 50px;
        height: 50px;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }

  .home-img {
    width: 100%;
    margin-bottom: 50px;
    cursor: pointer;
  }
  .main-content {
    width: 100%;
    //height: 718px;
    background-color: #F5F5F7;
    // padding: 25px 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 240px;
    .nav-bar {
      width: 100%;
      //height: 56px;
      display: flex;
      padding: 10px 100px;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      background: white;
      gap: 10px;
      .nav-item {
        font-size: 9px;
        font-style: normal;
        font-weight: 700;
        color: #042459;
        text-transform: uppercase;
        line-height: 100%;
        &.active {
          // color: #FFF;
        }
      }
    }
    .quote-container {
      display: flex;
      flex-direction: row;
      gap: 25px;
      flex: 1;
      .left {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .title {
          font-family: PT Sans;
          color: #3B87B2;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: uppercase;
        }
        .description {
          font-family: PT Sans;
          color: #000;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 30px; /* 150% */
        }
        .map {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 50px;
          margin-left: 10px;
          img {
            width: 100%;
          }
        }
      }
      .right {  
        background-color: #fff;
        border-radius: 20px;
        padding: 50px;
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;
        padding-bottom: 30px;
        margin-right: 20px;
        .pickup-container {
          flex: 0.5;
          .title {
            color: #042459;
            font-family: PT Sans;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
            margin-bottom: 10px;
          }
          .location-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 5px;
            .location {
              border: 1px solid #000;
              border-radius: 4px;
              color: #000;
              font-family: PT Sans;
              font-size: 9px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              padding: 2px 5px;
              margin: 2px 0px;
            }
          }
        }
        .drop-off-container {
          flex: 0.3;
          display: flex;
          flex-direction: column;
          gap: 10px;
          .title {
            color: #042459;
            font-family: PT Sans;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
          }
          .input {
            display: flex;
            //height: 50px;
            padding: 0px 9px;
            align-items: center;
            gap: 10px;
            align-self: stretch;
            border-radius: 4px;
            border: 1px solid #000;
            margin: 10px 0px;
            .icon {
              display: flex;
              width: 24px;
              height: 24px;
              justify-content: center;
              align-items: center;
              img {
                width: 100%;
              }
            }
            .placeholder {
              color: rgba(0, 0, 0, 0.30);
              font-family: PT Sans;
              font-size: 9px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
        .quote-btn {
          display: flex;
          padding: 15px 20px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          align-self: stretch;
          border-radius: 8px;
          background: #007EF8;
          height: 28px;
          .btn-text {
            color: #FFF;
            text-align: center;
            font-family: PT Sans;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }
      }
    }
  }
  .home-bottom-container {
    position: absolute;
    bottom: 6px;
    left: 20px;
    display: flex;
    align-items: center;
    gap: 16px;
    // margin-bottom: 73px;

    .home-bottom-item-container {
      width: 30px;
      height: 20px;
      // padding: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // background: #FFF;
      // overflow: hidden;

      .home-bottom-item-title {
        position: relative;
        width: 60px;
        height: 30px;
        display: flex;
        justify-content: center;
        margin-bottom: 2px;
        .home-bottom-item-title-text{
          color: #fff;
          text-align: center;
          font-size: 8px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          // text-transform: uppercase;
          // letter-spacing: -1px;
          position: absolute;
          bottom: 0;
        }
      }

      .home-bottom-item-img-container {
        width: 25px;
        height: 25px;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
}
