.getAnInstantCodeComingSoon {
    width: 1040px !important;
    .ant-modal-content {
        padding: 20px 145px;
        .ant-modal-body {
            text-align: center;
            margin: 100px 0;
            > h1 {
                font-weight: 700;
                font-size: 32px;
                line-height: 41px;
                color: #042459;
            }
            > p {
                font-weight: 400;
                font-size: 20px;
                line-height: 30px;
                color: #042459;
                margin: 50px 0;
            }
            > img {
                width: 100%;
                margin-bottom: 50px;
            }
            .ant-btn {
                color: #fff;
                width: 100%;
                height: 64px;
                border-radius: 16px;
                font-size: 20px;
                line-height: 25px;
                background: #C6CDD5;
            }
        }
    }
}
