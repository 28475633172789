.footer-main {
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  background: #042459;
  > span {
    font-weight: bold;
  }
}
