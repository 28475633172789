.footerAll-mobile-main {
  // background: #fbfbfb;

  .footerAll-main-left {
    margin-bottom: 35px;
    color: #042459;

    .footerAll-main-left-logo {
      width: 100px;
    }

    .footerAll-main-left-p {
      font-size: 12px;
      margin: 10px 0;
      overflow: hidden; //超出的文本隐藏
      text-overflow: ellipsis; //溢出用省略号显示
      white-space: nowrap; // 默认不换行；
    }

    .footerAll-main-left-p1 {
      margin: 25px 0;
    }

    .footerAll-main-left-h {
      color: #042459;
      font-weight: 700;
      font-size: 14px;
      margin: 35px 0 15px;
    }

    .footerAll-main-left-uspan {
      width: 100%;
      cursor: pointer;
    }
  }

  .footerAll-main-right {
    color: #042459;
    .footerAll-main-right-h {
      font-weight: 700;
      font-size: 14px;
      color: #042459;
      margin-bottom: 15px;
    }

    .footerAll-main-right-h3 {
      margin-top: 50px;
    }

    .footerAll-main-right-ul1 {
      list-style: none;
      margin: 0 0 35px;
      padding: 0;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;

      .header-main-header-main-right-ul-li {
        font-size: 12px;
      }
    }

    .footerAll-main-right-ul2 {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;

      .header-main-header-main-right-ul-li {
        margin-right: 10px;
        width: 23px;
        height: 23px;

        img {
          max-width: 100%;
          max-height: 100%;
          cursor: pointer;
        }
      }
    }

    .footerAll-main-right-p {
      font-size: 12px;
      margin: 10px 0;
    }

    .footerAll-main-right-qr-code-info-container {
      display: flex;
      align-items: center;
      gap: 15px;
      padding-top: 25px;

      .footerAll-main-right-qr-code-info-item-container {
        padding: 3px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #FFF;
        overflow: hidden;

        .footerAll-main-right-qr-code-info-item-title {
          text-align: center;
          font-size: 10px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: uppercase;
          letter-spacing: -1px;
          color: #061C3D;
          width: 80px;
        }
        .footerAll-main-right-qr-code-info-item-logo{
          width: 30px;
          height: 30px;
          margin: 15px;
          img {
            max-width: 100%;
            max-height: 100%;
          }
        }

        .footerAll-main-right-qr-code-info-item-img-container {
          width: 80px;
          height: 80px;

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
    }
  }
}
