.contact-mobile-main {
  position: relative;
  // width: 300px;
  width: 100%;
  padding: 50px;
  background-image: url("../../assets/images/home/backgroundImg.png");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100%;
  color: #042459;
  > h1 {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #042459;
  }
  > img {
    width: 100%;
    margin: 20px 0;
  }
  .footerAll-main {
    background: #fff;
  }
  .contact-main-form {
    width: 250px;
    margin: 30px auto;
    > h1 {
      font-weight: 700;
      font-size: 16px;
      padding: 20px 0;
      text-align: center;
      color: #042459;
      border-bottom: 1px #000 solid;
      border-top: 1px #000 solid;
    }
  }
  .form-main {
    .ant-form-item {
      .ant-input {
        height: 36px;
        font-size: 14px;
      }
      .ant-form-item-label {
        > label {
          font-size: 12px;
        }
        .ant-form-item-required {
          &:after {
            content: '*';
            display: block;
            color: #ee1d52;
          }
        }
      }
    }
    .display-center {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .ant-form-item {
        width: 46%;
      }
    }
    .ant-form-item-control-input-content {
      .ant-btn {
        width: 100%;
        height: 34px;
        margin: 50px 0;
        font-size: 12px;
        border-radius: 12px;
        background: #007EF8;
        color: #fff;
      }
    }
  }
  .home-bottom-container {
    position: absolute;
    bottom: 6px;
    left: 20px;
    display: flex;
    align-items: center;
    gap: 16px;
    color: #fff;
    // margin-bottom: 73px;

    .home-bottom-item-container {
      width: 30px;
      height: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // overflow: hidden; 
      // font-size: 8px;

      .home-bottom-item-title {
        position: relative;
        width: 60px;
        height: 30px;
        display: flex;
        justify-content: center;
        .home-bottom-item-title-text{
          // color: #fff;
          text-align: center;
          font-size: 8px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          // text-transform: uppercase;
          // letter-spacing: -1px;
          position: absolute;
          bottom: 0;
        }
      }

      .home-bottom-item-img-container {
        width: 25px;
        height: 25px;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
}
