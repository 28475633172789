.feedback-modal {
  width: 1275px !important;
  min-width: 736px;

  .ant-modal-content {
    width: 100%;
    padding: 100px 200px;

    .ant-modal-body {
      width: 100%;
    }
  }

  .feedback-container {
    width: 100%;
    background: rgba(255, 255, 255, 0.92);
    display: flex;
    flex-direction: column;
    align-items: center;

    .feedback-title {
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      text-align: center;
      text-transform: uppercase;
      color: #042459;
      margin-bottom: 50px;
    }

    .feedback-des {
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      color: #042459;
      margin-bottom: 50px;
    }

    .feedback-form-container {
      width: 100%;

      .feedback-form-row {
        display: flex;
        gap: 0 40px;

        .ant-form-item {
          flex: 1;
        }
      }
      .formLabel {
        font-size: 24px;
        color: #042459
      }

      .ant-form-item {
        margin-bottom: 40px;
        .ant-form-item-label {
          font-weight: 700;
          font-size: 16px;
          line-height: 28px;
          color: #666666;
          padding-bottom: 12px;

          .ant-form-item-required {
            &:after {
              content: '*';
              display: block;
              color: #EE1D52;
            }
          }
        }

        .ant-input {
          height: 56px;
          border: 1px solid #000;
          border-radius: 20px;
          font-size: 16px;
        }
      }
    }

    .feedback-btn-1 {
      width: 100%;
      height: 55px;
      // border: 1px solid #111111;
      border-radius: 16px;
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      color: #fff;
      background: #007EF8;
      margin-bottom: 20px;
    }

    .feedback-btn-2 {
      width: 100%;
      height: 55px;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      color: #fff;
      background: #C6CDD5;
      border-radius: 16px;
    }
  }

  .feedback-success-container {
    width: 100%;
    background: rgba(255, 255, 255, 0.92);
    padding: 0 90px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .feedback-success-title {
      font-weight: 700;
      font-size: 32px;
      line-height: 41px;
      text-align: center;
      text-transform: uppercase;
      color: #3B87B2;
      margin-bottom: 50px;
    }

    .feedback-success-des {
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      color: #000000;
      margin-bottom: 50px;
    }
    
    .feedback-success-btn {
      width: 100%;
      height: 64px;
      border: 1px solid #111111;
      border-radius: 40px;
      font-weight: 400;
      font-size: 20px;
      line-height: 25px;
      color: #111111;
    }
  }
}
