.mobile-preview-page-style{
  width: 100vw;
  flex: 1;
  moz-user-select: -moz-none;
  -moz-user-select: none;
  -o-user-select:none;
  -khtml-user-select:none;
  -webkit-user-select:none;
  -ms-user-select:none;
  user-select:none;
  #header-bar{
    display: none;
  }
}
