.blog-articles-container {
  padding-bottom: 100px;

  .blog-articles-title {
    font-weight: 700;
    font-size: 36px;
    line-height: 46px;
    text-align: center;
    text-transform: uppercase;
    color: #0DC9DD;
    margin-bottom: 100px;
  }

  .blog-articles-list-container {
    display: grid;
    gap: 25px 50px;
    grid-template-columns: 330px 330px 330px;
  }

  .blog-articles-btn-container {
    padding-top: 50px;

    .blog-articles-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px 20px;
      gap: 10px;
      background: #3B87B2;
      border-radius: 8px;
      width: max-content;
      margin: 0 auto;

      font-weight: 400;
      font-size: 18px;
      line-height: 23px;
      color: #FFFFFF;
      cursor: pointer;
      user-select: none;
    }
  }
}
