.getAnInstantCodeOTR {
  width: 1390px !important;
  .get-an-instant-code-fba-container{
    background: #f5f5f7;
    display: flex;
    justify-content: space-between;
    .get-an-instant-code-fba-left-container {
      width: 700px;
      display: flex;
      align-items: center;
      .get-an-instant-code-fba-left-img-container {
        width: 100%;
        height: 700px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .get-an-instant-code-fba-right-container {
      width: 570px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: #fff;
      border-radius: 20px;
      padding: 50px;
    }
  }
  .ant-modal-content {
    min-width: 1300px;
    padding: 80px 30px;
    background: #f5f5f7;
    .ant-modal-body {
      text-align: center;
      // margin: 100px 0;
      .getAnInstantCodeOTR-title {
        font-weight: 700;
        font-size: 32px;
        line-height: 41px;
        color: #042459;
      }
      .getAnInstantCodeOTR-text {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #ee1d52;
        margin: 50px 0;
      }
      .getAnInstantCode-vehicleType {
        width: 100%;
        .getAnInstantCode-vehicleType-title {
          text-align: left;
          > h1 {
            font-weight: 700;
            font-size: 24px;
            line-height: 28px;
            color: #042459;
          }
        }
        .getAnInstantCode-vehicleType-btns {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .getAnInstantCode-vehicleType-btn-1 {
            padding: 0 20px;
            width: 28%;
            height: 40px;
            border-radius: 20px;
            font-weight: 400;
            font-size: 16px;
            background: #FFFFFF;
            color: #000000;
            margin-bottom: 32px;
            border: 1px solid #000;
          }
          .getAnInstantCode-vehicleType-btn-2 {
            padding: 0 20px;
            width: 28%;
            height: 40px;
            border-radius: 20px;
            font-weight: 400;
            font-size: 16px;
            background: #222857;
            color: #FFFFFF;
            margin-bottom: 32px;
            border: 1px solid #007EF8;
          }
        }
      }
      .getAnInstantCodeOTR-from {
        // display: flex;
        // justify-content: space-between;
        align-items: center;
        .getAnInstantCodeOTR-from-left {
          width: 98%;
          text-align: left;
          // padding-bottom: 15px;
          .getAnInstantCodeOTR-from-left-div {
            > h1 {
              font-weight: 700;
              font-size: 24px;
              line-height: 28px;
              color: #042459;
            }
            // .ant-input {
            //   font-size: 16px;
            //   height: 56px;
            // }
            .ant-select {
              width: 100%;
              .ant-select-selector {
                height: 50px;
                border: 1px solid #000;
                border-radius: 20px;
                font-size: 16px;
              }
            }
          }
        }
        .getAnInstantCodeOTR-from-right {
          width: 98%;
          text-align: left;
          .getAnInstantCodeOTR-from-right-div {
            > h1 {
              font-weight: 700;
              font-size: 24px;
              line-height: 28px;
              color: #042459;
            }
            .ant-select {
              width: 100%;
              .ant-select-selector {
                height: 50px;
                border: 1px solid #000;
                border-radius: 20px;
                font-size: 16px;
                .ant-select-selection-search-input {
                  height: 100%;
                }

                .ant-select-selection-placeholder {
                  height: 100%;
                  line-height: 54px;
                }

                .ant-select-selection-item {
                  height: 100%;
                  line-height: 54px;
                }
                .ant-select-selection-search {
                  .ant-select-selection-search-input {
                    font-size: 16px;
                  }
                }
              }
            }
          }
        }
      }
      .getAnInstantCodeOTR-mar {
        margin: 70px 0;
        .getAnInstantCodeOTR-mar-ul {
          margin: 0;
          padding: 0;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .getAnInstantCodeOTR-mar-ul-li {
            width: 120px;
            height: 80px;
            border: 1px solid #0dc9dd;
            border-radius: 8px;
            margin: 30px 10px 0;
            padding: 10px;
            > h1 {
              font-weight: 400;
              font-size: 20px;
              line-height: 30px;
              color: #061c3d;
              margin: 0;
            }
            > p {
              font-weight: 700;
              font-size: 16px;
              line-height: 30px;
              color: #3b87b2;
              margin: 0;
            }
          }
        }
      }
      .getAnInstantCodeOTR-leftText {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #3b87b2;
        text-align: left;
        margin: 50px 0;
      }
      .getAnInstantCodeOTR-btn {
        width: 100%;
        height: 55px;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        border-radius: 16px;
        margin-bottom: 20px;
        color: #fff;
        background: #007EF8;
        &:last-of-type {
          background: #C6CDD5;
        }
      }
    }
  }
}
