.getAnInstantCodeWarehouse {
  width: 1390px !important;

  .ant-modal-content {
    width: 100%;
    min-width: 1305px;
    padding: 60px 30px;
    background: #F5F5F7;

    .ant-modal-body {
      width: 100%;
    }
  }

  .get-an-instant-code-warehouse-container {
    width: 100%;
    background: #F5F5F7;
    display: flex;
    justify-content: space-between;
    align-items: center; 

    .get-an-instant-code-warehouse-link {
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: 0;
      text-align: left;
      color: #3B87B2;
      text-transform: uppercase;
      padding: 0 4px;
    }

    .get-an-instant-code-warehouse-left-container {
      width: 700px;
      .get-an-instant-code-warehouse-left-title {
        font-weight: 700;
        font-size: 32px;
        line-height: 41px;
        color: #3B87B2;
        text-transform: uppercase;
        margin-bottom: 50px;
      }

      .get-an-instant-code-warehouse-left-des {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #000000;
        margin-bottom: 30px;
      }

      .get-an-instant-code-warehouse-left-tip {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #000000;
        margin-bottom: 50px;
      }

      .get-an-instant-code-warehouse-left-img-container {
        width: 100%;
        //height: 252px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        overflow: hidden;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }

    .get-an-instant-code-warehouse-right-container {
      width: 570px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 50px;
      border-radius: 20px;
      background: #fff;
      .get-an-instant-code-warehouse-right-top-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 0px 46px;
        margin-bottom: 20px;
      }

      .get-an-instant-code-warehouse-right-title {
        letter-spacing: 0;
        // text-align: center;
        color: #042459;
        font-size: 32px;
        font-weight: 600;
        line-height: 44.8px;
        letter-spacing: 0;
      }
      .get-an-instant-code-warehouse-right-title-special{
        margin-bottom: 20px;
      }

      .get-an-instant-code-warehouse-right-btn-1 {
        padding: 0 20px;
        width: 45%;
        height: 40px;
        border-radius: 20px;
        font-weight: 400;
        font-size: 16px;
        margin-bottom: 49px;
        color: #000;
        background: #fff;
        border: 1px solid #000;
      }
      .get-an-instant-code-warehouse-right-btn-1-active {
        color: #FFFFFF;
        background: #222857;
        border: 1px solid #007EF8;
      }

      .get-an-instant-code-warehouse-right-btn-2 {
        width: 100%;
        height: 55px;
        border-radius: 16px;
        font-weight: 600;
        font-size: 18px;
        line-height: 25.2px;
        color: #fff;
        background: #C6CDD5;
        padding: 15px 20px;
      }
    }
  }
}
