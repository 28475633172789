.blog-mobile-articles-item-container {
  width: 100%;
  cursor: pointer;

  .blog-articles-item-img-container {
    width: 100%;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .blog-articles-item-info-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;

    .blog-articles-item-info-left-container {
      display: flex;
      align-items: center;

      .blog-articles-item-info-left-icon-container {
        width: 8px;
        height: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 3px;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      .blog-articles-item-info-left-text {
        font-weight: 400;
        font-size: 9px;
        line-height: 20px;
        color: #4F4F4F;
      }
    }

    .blog-articles-item-info-date {
      font-weight: 400;
      font-size: 9px;
      line-height: 20px;
      color: #4F4F4F;
    }
  }

  .blog-articles-item-des {
    font-weight: 700;
    font-size: 10px;
    line-height: 13px;
    color: #0DC9DD;
  }
}
