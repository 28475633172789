.footerAll-main {
  background: #fbfbfb;
  display: flex;
  justify-content: space-between;

  .footerAll-main-left {
    width: 48%;
    color: #042459;

    .footerAll-main-left-logo {
      width: 100%;
      margin-bottom: 118px;
    }

    .footerAll-main-left-p {
      font-size: 20px;
      margin: 10px 0;
      width: 90%;
    }

    .footerAll-main-left-p1 {
      margin: 50px 0;
    }

    .footerAll-main-left-h {
      color: #042459;
      font-weight: 600;
      font-size: 32px;
      line-height: 44.8px;
      margin: 40px 0;
    }

    .footerAll-main-left-uspan {
      // border-bottom: 2px solid #000;
      width: 100%;
      cursor: pointer;
    }
  }

  .footerAll-main-right {
    width: 48%;
    color: #042459;
    font-size: 20px;
    padding-top: 30px;
    margin-bottom: 40px;

    .footerAll-main-right-h {
      // font-weight: 700;
      font-size: 32px;
      // color: #3b87b2;
      margin: 50px 0;
    }

    .footerAll-main-right-h3 {
      margin-top: 100px;
    }

    .footerAll-main-right-ul1 {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      .header-main-header-main-right-ul-li {
        font-size: 20px;
        margin: 0 40px 40px 0;
      }
    }

    .footerAll-main-right-ul2 {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;

      .header-main-header-main-right-ul-li {
        margin-right: 20px;
        width: 45px;
        height: 45px;

        img {
          max-width: 100%;
          max-height: 100%;
          cursor: pointer;
        }
      }
    }

    .footerAll-main-right-p {
      font-size: 20px;
      margin: 10px 0;
    }

    .footerAll-main-right-qr-code-info-container {
      display: flex;
      align-items: center;
      gap: 30px;
      padding-top: 50px;

      .footerAll-main-right-qr-code-info-item-container {
        padding: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // background: #FFF;
        overflow: hidden;
        height: 315px;

        .footerAll-main-right-qr-code-info-item-title {
          text-align: center;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 18.2px;
          // text-transform: uppercase;
          // letter-spacing: -1px;
          color: #061C3D;
          width: 100px;
          height: 45px;
        }
        .footerAll-main-right-qr-code-info-item-logo{
          width: 50px;
          height: 50px;
          margin: 15px;
          img {
            max-width: 100%;
            max-height: 100%;
          }
        }

        .footerAll-main-right-qr-code-info-item-img-container {
          width: 200px;
          height: 200px;

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
    }
  }
}
