.get-an-instant-code-port-rail-drayage-modal {
  width: 1390px !important;
  min-width: 1200px;

  .ant-modal-content {
    width: 100%;
    padding: 60px 30px;
    background: #f5f5f7;

    .ant-modal-body {
      width: 100%;
    }
  }

  .get-an-instant-code-port-rail-drayage-container {
    width: 100%;
    background: #f5f5f7;
    display: flex;
    justify-content: space-between;

    .get-an-instant-code-port-rail-drayage-left-container {
      width: 700px;
      display: flex;
      align-items: center;

      .get-an-instant-code-port-rail-drayage-left-title {
        font-weight: 700;
        font-size: 32px;
        line-height: 41px;
        color: #3B87B2;
        text-transform: uppercase;
        margin-bottom: 50px;
      }

      .get-an-instant-code-port-rail-drayage-left-des {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #000000;
        margin-bottom: 30px;
      }

      .get-an-instant-code-port-rail-drayage-left-tip {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #000000;
        margin-bottom: 50px;
      }

      .get-an-instant-code-port-rail-drayage-left-img-container {
        width: 100%;
        height: 475px;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }

    .get-an-instant-code-port-rail-drayage-right-container {
      width: 570px;
      background-color: #FFFFFF;
      padding: 50px;
      border-radius: 20px;

      .get-an-instant-code-port-rail-drayage-right-title {
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
        // text-transform: uppercase;
        color: #042459;
        margin-bottom: 20px;
      }

      .get-an-instant-code-port-rail-drayage-transport-type-list-container {
        display: flex;
        flex-wrap: wrap;
        gap: 0 10px;
        margin-bottom: 40px;

        .get-an-instant-code-port-rail-drayage-transport-type-item-container {
          width: 130px;
          padding: 0 19px;
          border: 1px solid #000000;
          border-radius: 20px;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #000000;
          height: 40px;
        }

        .get-an-instant-code-port-rail-drayage-transport-type-active-item-container {
          border-color: #007EF8;
          color: #FBFBFB;
          background-color: #222857;
          ;
        }
      }

      .get-an-instant-code-port-rail-drayage-pick-up-location-list-container {
        display: flex;
        gap: 30px 10px;
        flex-wrap: wrap;
        margin-bottom: 40px;

        .get-an-instant-code-port-rail-drayage-pick-up-location-item-container {
          padding: 0 19px;
          border: 1px solid #000000;
          border-radius: 20px;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #000000;
          height: 40px;
        }

        .get-an-instant-code-port-rail-drayage-pick-up-location-active-item-container {
          border-color: #007EF8;
          color: #FBFBFB;
          background-color: #222857;
        }
        
        .get-an-instant-code-port-rail-drayage-pick-up-location-item-box-shadow {
          // box-shadow: 0 4px 4px 0 #0A66C2;
        }
      }
      
      .get-an-instant-code-port-rail-drayage-right-search-container {
        position: relative;
        height: 50px;
        margin-bottom: 50px;

        .get-an-instant-code-port-rail-drayage-right-input-icon {
          width: 24px;
          height: 24px;
          position: absolute;
          left: 19px;
          top: 50%;
          transform: translateY(-50%);
          z-index: 9;
        }
        
        .get-an-instant-code-port-rail-drayage-right-input-container {
          width: 100%;
          height: 100%;

          .ant-select-selector {
            height: 100%;
            width: 100%;
            padding: 0 19px 0 53px;
            border: 1px solid #000000 !important;
            border-radius: 20px;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;

            .ant-select-selection-search {
              height: 100%;
              inset-inline-start: 53px;
              inset-inline-end: 19px;

              input {
                height: 100%;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
              }
            }

            .ant-select-selection-item,
            .ant-select-selection-placeholder {
              display: flex;
              align-items: center;
            }
          }
        }
      }

      .get-an-instant-code-port-rail-drayage-right-btn {
        padding: 0 20px;
        width: 100%;
        height: 50px;
        background: #007EF8;
        border-radius: 16px;
        font-weight: 600;
        font-size: 18px;
        color: #FFFFFF;
        font-family: PingFang TC;
      }
    }
  }
}
