.blog-detail-container {
  width: 100%;
  padding: 0 191px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .blog-detail-top-container {
    width: 1020px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .blog-detail-top-img-container {
      width: 1020px;
      height: 600px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .blog-detail-top-info-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;

      .blog-detail-top-info-left-container {
        display: flex;
        align-items: center;

        .blog-detail-top-info-left-icon-container {
          width: 15px;
          height: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 5px;

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }

        .blog-detail-top-info-left-text {
          font-weight: 400;
          font-size: 12px;
          line-height: 26px;
          color: #4F4F4F;
        }
      }

      .blog-detail-top-info-date {
        font-weight: 400;
        font-size: 12px;
        line-height: 26px;
        color: #4F4F4F;
      }
    }

    .blog-detail-top-title {
      font-weight: 700;
      font-size: 32px;
      line-height: 41px;
      color: #393939;
      width: 780px;
      margin-right: auto;
      margin-bottom: 30px;
    }

    .blog-detail-top-des-container {
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      color: #686A6F;
      margin-bottom: 110px;

      .blog-detail-top-des-bold {
        font-weight: 700;
      }

      .blog-detail-top-des-margin-bottom-1 {
        margin-bottom: 30px;
      }

      a {
        color: #686A6F !important;
      }
    }
  }
}
